<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">首页</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="form-box flexdc" style="height:100%">
              <div class="flexcb card-box">
                <div
                  class="card-item flexdc"
                  @click="doLook('/web/shanxiSupervisionEnd/InstitutionalStatistics')"
                >
                <div class="card-top">
                      <span>辖区机构总数</span>
                  <span class="card-num">{{ Count.compNum || 0 }}</span>
                </div>
                  <div class="df card-bottom">
                    <span>本月新增机构数量</span>
                    <span style="margin-left:10px;font-weight:600">{{ Count.newCompNum || 0 }}</span>
                  </div>
                </div>
                <div
                  class="card-item flexdc"
                  @click="doLook2('/web/shanxiSupervisionEnd/StudentlStatistics')"
                >
                <div class="card-top">
                  <span>参与培训总人次</span>
                  <span class="card-num">{{ Count.trainingPeopleNum || 0 }}</span>
                </div>
                  <div class="df card-bottom">
                    <span>本月新增学员数量</span>
                    <span style="margin-left:10px;font-weight:600">{{ Count.newTrainingPeopleNum || 0 }}</span>
                  </div>
                </div>
                <div
                  class="card-item flexdc"
                  @click="doLook2('/web/shanxiSupervisionEnd/StudentlStatistics','10','30')"
                >
                <div class="card-top">
                      <span>学习已完成人次</span>
                  <span  class="card-num">{{ Count.learnFinishedPeopleNum || 0 }}</span>
                    
                </div>
                  <div class="df card-bottom">
                     <span>本月新学习完成学员人次</span>
                    <span style="margin-left:10px;font-weight:600">{{ Count.newLearnFinishedPeopleNum || 0 }}</span>
                  </div>
                </div>
              </div>
              <div class="flexdc" style="height:100%">
                <h3 class="title">区域统计</h3>
                <div class="echerts-box flexdc">
                  <div class="btn-box">
                    <el-button
                      size="mini"
                      :class="this.stu =='mechanism'? 'bgc-bv' :'active'"
                      @click="getCompanyEchart('mechanism')"
                      v-show="areaLevel != 3"
                      >机构总数</el-button
                    >
                    <!-- <el-button
                      size="mini"
                      :class="this.stu =='company'? 'bgc-bv' :'active'"
                      @click="getCompanyEchart('company')"
                      >企业总数</el-button
                    >
                    <el-button
                      size="mini"
                      :class="this.stu =='class'? 'bgc-bv' :'active'"
                      @click="getCompanyEchart('class')"
                      >开班总数</el-button
                    > -->
                    <el-button
                      size="mini"
                      :class="this.stu =='student'? 'bgc-bv' :'active'"
                      @click="getCompanyEchart('student')"
                      >培训学员</el-button
                    >
                  </div>
                  <div
                    class="echarts flex1"
                    id="echart1"
                    style="height:500px"
                    v-show="echartsShow"
                  ></div>
                  <div
                    style=" height:100%; flex:1;position:relative"
                    v-show="trues"
                  >
                    <div style="height:100%; display:flex;">
                      <img
                        src="@/assets/empty.png"
                        alt=""
                        style="width:300px; height:185px"
                        class="imgCenter"
                      />
                      <span class="span">暂无数据</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LoginRecommend />
  </div>
</template>
<script>
let echart1 = {};
import LoginRecommend from "@/components/loginRecommend.vue";
export default {
  name: "SupervisionEnd/home",
  components: {
    LoginRecommend
  },
  data() {
    return {
      Count: {},
      Class: {},
      Company: {}, // 企业
      mechanism: {}, // 机构
      hideEmpty: true,
      trues: false,
      echartsShow: true,
      stu:'mechanism',
      areaLevel:1
    };
  },
  created() {
    this.init();
  },
  mounted() {
    // this.initEchart();
    echart1 = this.$echarts.init(document.getElementById("echart1"));
    // this.getCompanyEchart();
  },
  computed: {},
  methods: {
    init() {
      this.getAreaLevel()
      this.getCount();
    },
    getAreaLevel() {
      this.$post('/agency/agency-gov-static/getUserAreaLevel').then(ret => {
        this.areaLevel = ret.data;
        if(this.areaLevel == 3) {
          this.stu ='student'
           this.getCompanyEchart(this.stu)
        } else {
          this.stu ='mechanism'
           this.getCompanyEchart(this.stu)
        }
       
      })
    },
    // 学员统计
    getCount() {
      this.$post("/agency/agency-gov-static/count").then((res) => {
        if (res.status == "0") {
          this.Count = res.data;
        }
      });
    },
    // 企业图表
    getCompanyEchart(stu) {
      this.stu  = stu 
      console.log(stu);
      // if(this.areaLevel == 3 || stu == 3) {
      //   this.stu = 'student'
      // } else {
      //    this.stu = 'mechanism'
      // }
      let param = {};
      let tip = "";
      let url = "/companyBar";
      let path = "";
      if (this.stu  == "student") {
        url = "/studentBar";
        tip = "学员总数";
        param = {
         compType: "10",
        };
        path = "/web/shanxiSupervisionEnd/StudentlStatistics";
      } else if (this.stu  == "mechanism") {
        url = "/companyBar";
        tip = "机构总数";
        param = {
          compType: "20",
        };
        path = "/web/shanxiSupervisionEnd/InstitutionalStatistics";
      }
      this.$post("/agency/agency-gov-static" + url, param).then((res) => {
        if (
          res.data.barArea.length != 0 ||
          res.data.barXAxis.length != 0 ||
          res.data.barDate.length != 0
        ) {
          this.initEchart(res.data, tip, path);
          this.trues = false;
          this.echartsShow = true;
        } else {
          this.trues = true;
          this.echartsShow = false;
          document
            .getElementById("echart1")
            .removeAttribute("_echarts_instance_");
        }
      });
    },
    initEchart(params, tip, path) {
      const that = this;
      const option = {
        color: ["#5c6be8"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        xAxis: [
          {
            type: "category",
            data: params.barXAxis,
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              interval: 0,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: tip || "机构总数",
            type: "bar",
            barWidth: "45px",
            data: params.barDate,
          },
        ],
      };
      echart1.setOption(option);
      echart1.on("click", function(param) {
        console.log(param);
        let areaId = "";
        let companyName = "";
         if (that.areaLevel != 3) {
        params.barArea.forEach((item, index) => {
          if (index == param.dataIndex) {
            areaId = item;
          }
        });
         } else {
           companyName = param.name
         }
        if (tip == "学员总数") {
          param = "10";
           that.doLook3(path, companyName,areaId);
        } else if (tip == "机构总数") {
          param = "20";
           that.doLook(path, params, areaId);
        }
       
      });
      // window.resize(function() {
      //   echart1.resize();
      // });
      window.addEventListener("resize", function () {
        echart1.resize();
      });
    },
    doLook(url, params, areaId) {
      this.$router.push({
        path: url,
        query: {
          params,
          areaId,
          refresh:true,
        },
      });
    },
    doLook2(url, params, studyState) {
      this.$router.push({
        path: url,
        query: {
          params,
          studyState,
          refresh:true,
        },
      });
    },
    doLook3(url, companyName,areaId) {
      this.$router.push({
        path: url,
        query: {
          companyName,
          areaId,
          refresh:true,
        },
      });
    },
  },
};
</script>
<style lang="less"></style>
<style lang="less" scope>
.card-box {
  margin-bottom: 10px;
  min-height: 170px;
  .card-item {
    border-radius: 5px;
    min-width: 30%;
    height: 100%;
    justify-content: space-between;
    cursor: pointer;
    // span {
    //   line-height: 30px;
    //   color: #fff;
    // }
    // div {
    //   justify-content: space-between;
    // }
    .card-top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        span {
            font-size: 1rem;
            color:#fff;
        }
        .card-num {
               font-size: 1.5rem;
    font-weight: bolder;
        }
    }
    .card-bottom {
            min-height: 3rem;
        background: #000000;
        background-color:rgba(0,0,0,0.05);
border-radius: 0px 0px 1rem 1rem;
display: flex;
align-items: center;
justify-content: center;

span {
    opacity: 1;
    color:#fff;
    font-size: .85rem;
}
    }
  }
  & > :nth-child(1) {
      
background: #42A5F5;
border-radius: 1rem;

box-shadow: 0px 0px 20px rgba(66, 165, 245, 0.3);
    // background-image: url(../../../assets/first.png);
    // background-size: cover;
  }
  & > :nth-child(2) {
    background: #3CAFBC;
    border-radius: 1rem;
box-shadow: 0px 0px 20px rgba(60, 175, 188, 0.3);
    // background-size: cover;
  }
  & > :nth-child(3) {
    background: #5C6BC0;
    border-radius: 1rem;
box-shadow: 0px 0px 20px rgba(92, 107, 192, 0.3);
    // background-size: cover;
  }
//   & > :nth-child(4) {
//     background-image: url(../../../assets/fourth.png);
//     background-size: cover;
//   }
//   & > :nth-child(5) {
//     background-image: url(../../../assets/first.png);
//     background-size: cover;
//   }
}
h3.title {
  padding: 0.35rem;
  border: 1px solid #ccc;
  border-bottom: 0;
}
.echerts-box {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  .btn-box {
    align-self: flex-end;
    padding: 10px;
    margin-bottom: 10px;
    .el-button {
      &:focus {
        background-color: #5C6BE8;
        color: #fff;
        border-color: #5C6BE8;
      }
    }
    .active {
       background-color: rgb(218, 221, 250);
        color: rgb(92, 107, 232);
        border-color: rgb(218, 221, 250);
    }
  }
}
.span {
  position: absolute;
  /* left: 50%; */
  /* right: 50%; */
  text-align: center;
  width: 100%;
  top: 63%;
}
</style>
